@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}

button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: transparent;
  color: #1a8ed1;
  border: 1px solid #1a8ed1;
}

button:hover,
button:active {
  background-color: #1ac5d1;
  border-color: #1ac5d1;
  color: white;
}
.CartButton_button__3nU_b {
  background-color: transparent;
  border-color: #1ad1b9;
  color: #1ad1b9;
}

.CartButton_button__3nU_b:hover,
.CartButton_button__3nU_b:active {
  color: white;
}

.CartButton_button__3nU_b span {
  margin: 0 0.5rem;
}

.CartButton_badge__3njqM {
  background-color: #1ad1b9;
  border-radius: 30px;
  padding: 0.15rem 1.25rem;
  color: #1d1d1d;
}
.MainHeader_header__k5Wsz {
  width: 100%;
  height: 5rem;
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #252424;
}

.MainHeader_header__k5Wsz h1 {
  color: white;
}

.MainHeader_header__k5Wsz ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Card_card__1m44e {
  margin: 1rem auto;
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
}

.ProductItem_item__WYS0J h3 {
  margin: 0.5rem 0;
  font-size: 1.25rem;
}

.ProductItem_item__WYS0J header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.ProductItem_price__1GiPR {
  border-radius: 30px;
  padding: 0.15rem 1.5rem;
  background-color: #3a3a3a;
  color: white;
  font-size: 1.5rem;
}

.ProductItem_item__WYS0J p {
  color: #3a3a3a;
}

.ProductItem_actions__14Yr- {
  display: flex;
  justify-content: flex-end;
}
.Products_products__1PCvW h2 {
  color: white;
  margin: 2rem auto;
  text-align: center;
  text-transform: uppercase;
}

.Products_products__1PCvW ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Cart_cart__3GqLz {
  max-width: 30rem;
  background-color: #313131;
  color: white;
}

.Cart_cart__3GqLz h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.Cart_cart__3GqLz ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.CartItem_item__1zMOi {
  margin: 1rem 0;
  background-color: #575757;
  padding: 1rem;
}

.CartItem_item__1zMOi h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.75rem;
}

.CartItem_item__1zMOi header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.CartItem_details__2_Z3n {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_quantity__DfEXm span {
  font-size: 1.5rem;
  font-weight: bold;
}

.CartItem_price__2Nl0j {
  font-size: 1.5rem;
  font-weight: bold;
}

.CartItem_itemprice__j9ZSt {
  font-weight: normal;
  font-size: 1rem;
  font-style: italic;
}

.CartItem_actions__UwVPJ {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.CartItem_actions__UwVPJ button {
  background-color: transparent;
  border: 1px solid white;
  margin-left: 0.5rem;
  padding: 0.15rem 1rem;
  color: white;
}

.CartItem_actions__UwVPJ button:hover,
.CartItem_actions__UwVPJ button:active {
  background-color: #4b4b4b;
  color: white;
}
